import React from "react";
import { Menu, MenuProps } from "antd";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { FormattedMessage } from "react-intl";
import { ACTION, SECTION, SectionAuth } from "@services/auth";

interface ICustomMenu {
  items?: { id: string; path: string; page: string; subpage: string }[];
}

interface IMenu extends MenuProps, ICustomMenu {}

const MenuTabs: React.FC<IMenu> = ({ items, ...rest }) => {
  const location = useLocation();
  const handleClick = ({ key }: any) => {
    navigate(key);
  };

  const auth = new SectionAuth(SECTION.Admin);

  const menuItems = [];
  if (items) {
    for (const item of items) {
      if (auth.can(ACTION.Access, item.page, item.subpage)) {
        menuItems.push(
          <Menu.Item key={item.path}>
            <FormattedMessage id={item.id} />
          </Menu.Item>
        );
      }
    }
  }

  return (
    <Menu
      selectedKeys={[location.pathname]}
      onClick={handleClick}
      mode="horizontal"
      {...rest}
    >
      {menuItems}
    </Menu>
  );
};

export default MenuTabs;
