import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const ReportsMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "ReportScoringKeySetNavigationTitle",
        path: "/admin/report/scoringkeyset",
        page: "Reports",
        subpage: "ScoringKeySet",
      },
      {
        id: "ReportNormTableNavigationTitle",
        path: "/admin/report/normtable",
        page: "Reports",
        subpage: "NormTables",
      },
      {
        id: "ReportReportTemplateNavigationTitle",
        path: "/admin/report/reporttemplate",
        page: "Reports",
        subpage: "ReportTemplates",
      },
      {
        id: "ReportGroupReportTemplateNavigationTitle",
        path: "/admin/report/groupreporttemplate",
        page: "Reports",
        subpage: "GroupReportTemplates",
      },
      {
        id: "ReportGenerationSettingNavigationTitle",
        path: "/admin/report/generationsetting",
        page: "Reports",
        subpage: "ReportGenerateSettings",
      },
    ]}
  />
);

export default ReportsMenu;
